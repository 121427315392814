import React, { forwardRef } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const Project = forwardRef((props, ref) => {

  const { name, logo, description, link, style } = props;

  return (
    <Card sx={{ minWidth: 325, maxWidth: 500 }} ref={ref} style={style}>
      <CardHeader title={name}/>
      <CardMedia  component='img' height={300} image={logo} alt={name}/>
      <CardContent>
        <Typography align='center' gutterBottom>{description}</Typography>
        {link ? <Link variant='h6' href={link}>See It Here</Link> : <Typography variant='h6' color='primary'>Coming Soon</Typography>}
      </CardContent>
    </Card>
  );
});

export default Project;