import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';

import Home from './containers/Home';

import Theme from './theme';

export default function App() {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline/>
      <Home/>
    </ThemeProvider>
  );
}