import React from "react";
import { useMediaQuery } from "@mui/material";

import Container from "@mui/material/Container";
import Fade from "@mui/material/Fade";
import Link from "@mui/material/Link";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import Projects from "./Projects";
import About from "../components/About";

import Logo from "../images/MWD.png";

const Home = () => {
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Container sx={{ pb: 4 }} maxWidth={false}>
      <Stack direction={mobile ? "column" : "row"} sx={{ mb: 6 }}>
        <Fade in={true} timeout={1500}>
          <img src={Logo} alt="Mitchellaneous Web Development" />
        </Fade>
        <Stack alignItems={mobile ? "center" : "start"}>
          <Slide in={true} direction="down" timeout={1000}>
            <Typography component="h2" variant="h1" color="primary">
              MWD
            </Typography>
          </Slide>
          <Slide in={true} direction="left" timeout={1000}>
            <Typography component="h1" variant="h6" sx={{ m: 0 }}>
              Mitchellaneous Web Development
            </Typography>
          </Slide>
          <Slide in={true} direction="left" timeout={1000}>
            <Link href="mailto:mitch@mwd.dev">e-mail for inquiries</Link>
          </Slide>
        </Stack>
      </Stack>
      <Projects />
      <Stack>
        <About />
      </Stack>
    </Container>
  );
};

export default Home;
