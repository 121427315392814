import React from 'react';

import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

const About = () => (
  <Fade in={true} timeout={1500}>
    <Typography align='center' sx={{ my: 8, maxWidth: '65ch' }}>
      My name is Mitch, I atttended the SAIT Web Developer Program in Fall 2019. Since then I have been working on these projects to hone my skills. I have experience with AWS EC2, S3, SES, NodeJS, ExpressJS, MongoDB, ReactJS, VueJS, Material UI, SCSS, CSS, and Auth0. Please contact me through the link at the top for a quote on your project!
    </Typography>
  </Fade>
);

export default About;