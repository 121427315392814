import React from "react";
import { TransitionGroup } from "react-transition-group";

import Grow from "@mui/material/Grow";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Project from "../components/Project";

import Radical from "../images/radicalhearts.png";
import FundSustain from "../images/fundsustain.png";
import Techmation from "../images/techmation.png";
import JamSpace from "../images/jamspace.png";

const Projects = () => {

  const projects = [
    {
      name: "Radical Hearts Healing",
      logo: Radical,
      description:
        "Single Page Application, built with React to promote Reiki Energy Healing",
      link: "https://www.radicalheartshealing.com",
    },
    {
      name: "FundSustain",
      logo: FundSustain,
      description:
        "Single Page Application built with React, NodeJS, MongoDB and Stripe. Built as a social platform to inspire giving back to the community. Extensive integration with Stripes payment gateway.",
      link: "https://fundsustain.com",
    },
    {
      name: "Techmation Electric",
      logo: Techmation,
      description:
        "Single Page Application built with React. Integration with Auth0",
      link: "https://solutions.techmationelectric.com",
    },
    {
      name: "Jam Space",
      logo: JamSpace,
      description:
        "Single Page Application built with Auth0, React, Mui, NodeJS, MongoDB. Connecting Muscians",
    },
  ];

  return (
    <Grid container spacing={4} justifyContent="center">
      <TransitionGroup component={null}>
        {projects.map((item, index) => (
          <Grow key={item.name} style={{ transitionDelay: 1500 + index * 150 }}>
            <Grid item xs={12} sm={10} md={6} lg={4} xl={3}>
              <Stack>
                <Project {...item} />
              </Stack>
            </Grid>
          </Grow>
        ))}
      </TransitionGroup>
    </Grid>
  );
};

export default Projects;
