import { createTheme } from "@mui/material/styles";

const Theme = createTheme({
  typography: {
    fontFamily: 'roboto, sans-serif'
  },
  components: {
    MuiCard: {
      defaultProps: {
        raised: true
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover'
      },
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer'
          }
        }
      }
    },
    MuiStack: {
      defaultProps: {
        alignItems: 'center',
        justifyContent: 'center'
      }
    },
  },
  palette: {
    mode: 'dark',
    background: { paper: '#162B50' },
    primary: { main: '#E77D50' },
    secondary: { main: '#294999' },
    error: { main: '#A61111' },
    warning: { main: '#FF7000' },
    info: { main: '#00AAFF' },
    success: { main: '#099100' }
  }
});

export default Theme;